import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px",
  },
  buttonsBar: {
    height: "8vh",
    width: "70%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    // marginTop: "3vh",
    marginLeft: "auto",
    marginRight: "auto",

    padding: "0px",

    // borderColor: "black",
    // borderWidth: " 0 0 1px 0",
    // borderStyle: "solid",
  },
  logoMain: {
    paddingTop: "2vh",
    display: "block",
    width: "300px",
    margin: "0 auto",
  },
  separator: {
    display: "block",
    width: "100px",
    margin: "0 auto",
  },
  navButtons: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "gray",
    },

    borderColor: "#fcfcfc",
    borderWidth: "1px",
    borderStyle: "solid",
    fontFamily: "Nunito Sans",
    fontSize: "0.8rem",
    margin: "0 30px",
  },
  socialMedia: {
    marginLeft: "50px",
  },
  mediaIcons: {
    margin: "0 7px",
    width: "18px",
    height: "18px",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        className={classes.logoMain}
        src="/icons/logoMano.svg"
        alt="instagram link"
      />
      <div className={classes.buttonsBar}>
        <Button
          disableRipple
          component={Link}
          to="/"
          className={classes.navButtons}
          href="#text-buttons"
        >
          Inicio
        </Button>

        {/* <Button
          disableRipple
          component={Link}
          to="/ilustraciones"
          className={classes.navButtons}
          href="#text-buttons"
        >
          Ilustraciones
        </Button> */}

        {/* <Typography style={{ display: "inline" }}>Logo</Typography> */}

        {/* <Button
          disableRipple
          component={Link}
          to="/cuentos"
          className={classes.navButtons}
          href="#text-buttons"
        >
          Cuentos
        </Button> */}
        <Button
          disableRipple
          component={Link}
          to="/acerca"
          className={classes.navButtons}
          href="#text-buttons"
        >
          Acerca De
        </Button>
        <div className={classes.socialMedia}>
          <a
            href="https://www.instagram.com/barrunto.elisa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.mediaIcons}
              src="/icons/instagram.png"
              alt="instagram link"
            />
          </a>
          <a
            href="https://www.facebook.com/barrunto.elisa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.mediaIcons}
              src="/icons/facebook.svg"
              alt="facebook link"
            />
          </a>
          <a
            href="https://www.instagram.com/barrunto.elisa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.mediaIcons}
              src="/icons/youtube.png"
              alt="youtube link"
            />
          </a>
          <a
            href="https://www.instagram.com/barrunto.elisa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.mediaIcons}
              src="/icons/pinterest.png"
              alt="pinterest link"
            />
          </a>
        </div>
      </div>
      <img
        className={classes.separator}
        src="/images/vectors/adornoPlantaDown.svg"
        alt="instagram link"
      />
    </div>
  );
}
