import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  image: {
    width: "30%",
  },
  description: {
    width: "50%",
    margin: "0 auto",
    textAlign: "left",
    ...theme.typography.bonito,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="cosa">
        <img
          className={classes.image}
          src="./images/Cuadros.png"
          alt="Here goes Elisa"
        />
      </div>
      <section className={classes.description}>
        Barrunto, es un espacio muy ecléctico tal como soy yo. De él salen
        libros, cuentos, historias, macramé, dibujos, pequeñas máquinas, entre
        otras cosas. Trabajo la imagen antigua como vehículo de imaginación y
        ventana a otras realidades pasadas.
      </section>
    </div>
  );
}
