export default [
  {
    img: "/images/IMG_20210108_184856_036.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210109_140042_138.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210113_144424_979.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210208_091909_635.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210216_142249_444.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210322_190302_335.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210325_161701_055.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210412_142754_522.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210623_152042_978.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210701_173350_694.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210705_202339_874.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210712_125505_428.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210712_125505_440.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
  {
    img: "/images/IMG_20210725_134910_716.jpg",
    title: "Ilustracion",
    author: "Elisa Borrero",
    cols: 1,
  },
];
