import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",

    padding: "30px",
    width: "70%",
    margin: "5vh",
    marginLeft: "auto",
    marginRight: "auto",

    borderColor: "black",
    borderWidth: " 1px 0 0 0",
    borderStyle: "solid",
    marginTop: "10vh",
  },
  copyright: {
    // display: "block",
    width: "50px",
    margin: "10px auto",
  },
  centerText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "20px",
    fontFamily: "Nunito Sans",
  },
  storyTeller: {
    width: "150px",
    margin: "0px auto",
  },
  anchor: {
    width: "100%",
    justifyContent: "center",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.centerText}>Barrunto, 2021</span>
      <img
        className={classes.copyright}
        src="/images/vectors/logoIcono.svg"
        alt="logo"
      />
      <a
        className={classes.anchor}
        href="https://www.storytelleracademy.com/?utm_source=Member%20website&utm_medium=Badge&utm_campaign=Makers%20Pro"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={classes.storyTeller}
          src="https://www.storytelleracademy.com/wp-content/uploads/2021/03/SA-Members-Badge_Artboard-Makers-Pro.png"
          alt="I’m a Storyteller Academy Makers Pro Member!"
        />
      </a>
    </div>
  );
}
