import React from "react";
import theme from "./Theme";

import { ThemeProvider } from "@material-ui/core/styles";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Acerca from "./Acerca";
import Gallery from "./Gallery";

import GalleryIlustracion from "./GalleryIlustracion";

export default function CenteredGrid() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <div style={{ textAlign: "center" }}>
            <Switch>
              <Route exact path={`/`} component={Gallery} />
              <Route path={`/ilustraciones`} component={GalleryIlustracion} />
              <Route path={`/cuentos`}>Cuentos</Route>
              <Route path={`/acerca`} component={Acerca} />
              <Route path={`/contacto`}>Contactanos</Route>
            </Switch>
          </div>
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}
