import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import tileData from "./titleData";
import ModalImage from "react-modal-image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "85%",
  },
  image: {
    aspectRatio: "1/1",
  },
}));

export default function ImageGridList() {
  const classes = useStyles();

  return (
    <div className={classes.root} npm i react-modal-image>
      <GridList
        cellHeight={"100px"}
        rowHeaigh={"100px"}
        spacing={5}
        className={classes.gridList}
        cols={3}
      >
        {tileData.map((tile, idx) => (
          <GridListTile
            className={classes.image}
            key={tile.img + "-" + idx}
            cols={tile.cols || 1}
          >
            <ModalImage
              small={tile.img}
              large={tile.img}
              alt={tile.title}
              hideZoom
              hideDownload
            />
            {/* <img src={tile.img} alt={tile.title} /> */}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
